import React from "react";
// import Img from "gatsby-image";

const Panier = () => {
  return (
    <div>
      {/* <Img fixed={data.placeholderImage.childImageSharp.fixed} /> */}

      <img
        src="https://res.cloudinary.com/dc0hoba1w/image/upload/v1607508272/small_panier_0629d32d81.png"
        alt="panier"
        width="20"
        // height= "565"
      />
    </div>
  );
};

export default Panier;
