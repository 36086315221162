import React from "react";
import "../assets/css/main.css";
import { Link } from "gatsby";
import "../fonts/fonts.css";
import "../images/panier.png";
import { Row, Col } from "reactstrap";

const style = {
  color: "black",
  textDecoration: "none",
};

const activeStyle = {
  color: "grey",
};

const NavBottom = () => (
  <>
    {/* // Version desktop */}

    <Row className="d-none d-md-flex nav bg-white fixed-bottom borderGeneral-top pl-1 pr-3">
      <Col sm="6" className="d-flex" >
       
      </Col>

      <Col sm="6" className="d-flex justify-content-between" >
      <Link
          className=" navFont navHover dix"
          to="/catalogue/"
          style={style}
          activeStyle={activeStyle}
        >
          <div className='pt-1 pb-1'>Catalogue </div>
        </Link>
        <Link
          className=" navFont navHover dix"
          to="/objets/"
          style={style}
          activeStyle={activeStyle}
        >
          <div className='pt-1 pb-1'>Objets</div>
        </Link>
        <Link
          className=" navFont navHover dix"
          to="/ounoustrouver/"
          style={style}
          activeStyle={activeStyle}
        >
          <div className='pt-1 pb-1 pr-1 '>Où nous trouver </div>
        </Link>
      </Col>
    </Row>


    {/* Version smartphone */}

    <Row className="d-flex d-md-none nav bg-white fixed-bottom borderGeneral-top pl-4 pr-4 justify-content-between pt-1 pb-1">
      <Link
        className=" navFont navHover dix"
        to="/catalogue/"
        style={style}
        activeStyle={activeStyle}
      >
        <div className=''>Catalogue </div>
      </Link>

      <Link
        className=" navFont navHover dix"
        to="/objets/"
        style={style}
        activeStyle={activeStyle}
      >
        <div className=''>Objets</div>
      </Link>

      <Link
        className=" navFont navHover dix"
        to="/ounoustrouver/"
        style={style}
        activeStyle={activeStyle}
      >
        <div className=''>Où nous trouver </div>
      </Link>
    </Row>


  </>
);

export default NavBottom;
